import { getOnlineUsers } from '../../../API/user'

import { UserType } from '../../../types/UserAdditionMetaData'
import AddClassesTable from '../../../components/admin/AddClassesTable'
import React, { Fragment, useEffect } from 'react'
import { useState } from 'react'

import { navigate } from 'gatsby'

export default function LiveUsers() {
	const [tableData, setTableData] = useState<Array<UserType>>([])
	const [selectedUsers, setSelectedUsers] = useState([])
	const columns = [
		{
			text: 'First Name',
			dataField: 'first_name',
			sort: true,
		},
		{
			text: 'Last Name',
			dataField: 'last_name',
			sort: true,
		},
		{
			text: 'Email',
			dataField: 'email',
			sort: true,
		},
		{
			text: 'Username',
			dataField: 'username',
			sort: true,
			headerAlign: 'center',
			align: 'center',
		},

		{
			text: 'Status',
			dataField: 'uid',
			sort: true,
			headerAlign: 'center',
			align: 'center',
			formatter: (e: string) => {
				// console.log(e)
				return (
					<>
						<div
							className='btn btn-primary'
							onClick={() => {
								navigate(`/admin/user/${e}`)
							}}
						>
							View
						</div>{' '}
					</>
				)
			},
		},
	]

	useEffect(() => {
		const run = async () => {
			const response: any = await getOnlineUsers()
			if (response) {
				const online_users = response.filter((elem: any) => new Date(elem.last_online_time).getTime() > 300000)
				setTableData(online_users)
			}
		}
		run()
	}, [])
	const run = async () => {
		const response: any = await getOnlineUsers()
		if (response) {
			const online_users = response.filter((elem: UserType) => new Date(elem.last_online_time).getTime() < 300000)
			setTableData(online_users)
		}
	}
	setInterval(function () {
		run()
	}, 300000)
	return (
		<Fragment>
			<div className='col-3'>
				<h2>Active Users</h2>
			</div>
			<AddClassesTable useSelect={[selectedUsers, setSelectedUsers]} data={tableData} columns={columns} />
		</Fragment>
	)
}
